import Header from './Components/Header';
import Footer from './Components/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import Home from "./Views/Home"
import About from "./Views/About"
import Projects from "./Views/Projects"
import Grid from "./Views/ProjectGrid"
import RandomUser from "./Views/RandomUser"
import "./Style/stylesheet.css"

function App() {
  return (
    <div className="">    
      <Router>
        <Header />

        <div className="p-3"> 
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/projects">
              <Projects />
            </Route>
            <Route exact path="/projectgrid">
              <Grid />
            </Route>
            <Route exact path="/randomuser">
              <RandomUser />
            </Route>
          </Switch>
        </div>

        <Footer />

      </Router>


    </div>
  );
}

export default App;
