import React from 'react'

function Footer(){
    return(
        <footer className='primary-dark text-center medium-spacing text-xs p-3 fixed bottom-0 w-full'>
            ||
            Phone: 864-238-1750
            ||
            ||
            Email: tylerthompson1492@gmail.com
            ||
        </footer>
    )
}

export default Footer;