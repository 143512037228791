import React from 'react'
import "../Style/stylesheet.css"
import  Github  from '../Images/github.svg'
import  Link  from '../Images/link-solid.svg'

function ProjectPanel(props)
{
    return(
        <div className="shadow-xl py-8">
                <div className="">
                    <img className="p-3 w-full" src={ props.image } alt=""/>
                </div>
                <div className="font-bold text-xl mb-2">
                    {props.projectName}
                    <div className="">
                        <a href={ props.github } target="_blank" rel="noreferrer">
                            <img className="project-grid-icon right-image" src={ Github } alt="Project Link"/>
                        </a>              
                        <a href={ props.link } target="_blank" rel="noreferrer">
                            <img className="project-grid-icon right-image" src={ Link } alt="Github Link"/>
                        </a>
                    </div>
      
                </div>
                <div>
                    <br></br>
                </div>

                <div className="p-3 text-base">
                    {props.description}
                </div>
                <div className="">
                    <br></br>
                </div>
               <div className="text-base">
                    {props.tools}
                </div>
        </div>



    )
}

export default ProjectPanel