import React from 'react'
import { Link } from "react-router-dom"

function NavigationMenu(props){
   
   
    return (
        <div className="primary-base nav-menu border-b border-r">
            <div className="font-bold p-3">
                <h1>
                    Tyler Thompson
                </h1>
            </div>
            <div className="p-3">
                <ul>
                    <li>
                        <Link 
                            to="/" 
                            className="py-3 border-t border-b block"
                            onClick= {props.closeMenu}
                            >Home</Link>
                    </li>
                    <li>
                        <Link 
                            to="/projects" 
                            className=" py-3 border-b block"
                            onClick= {props.closeMenu}>Featured Projects</Link>
                    </li>
                    <li>
                        <Link 
                            to="/projectgrid" 
                            className=" py-3 border-b block"
                            onClick= {props.closeMenu}>Other Notable Projects</Link>
                    </li>
                    <li>
                        <a href="https://www.github.com/Tathomp"
                            className=" py-3 border-b block"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Github Profile
                        </a>
                    </li>
                    <li>
                        <Link 
                            to="/about" 
                            className=" py-3 border-b block"
                            onClick= {props.closeMenu}>Copyright</Link>
                    </li>
   
                </ul>
            </div>
        </div>
    )
}

export default NavigationMenu