import React from 'react'
import "../Style/stylesheet.css"
import Folder from '../Images/folder.svg'
import  Github  from '../Images/github.svg'


function ProjectCell(props)
{
    return(
        <div className="primary-base grid grid-cols-3 gap-4 px-3 py-3">
            <div className="col-span-1">
                <img className="project-grid-icon"  src={ Folder } alt=""/>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-1">
                <a href={ props.link } target="_blank" rel="noreferrer">
                    <img className="project-grid-icon right-image" src={ Github } alt=""/>
                </a>
            </div>  
            <div className="font-bold col-span-3"> 
                <h1>
                    {props.title}                
                </h1> 
            </div>
            <div className="col-span-3 p-align-l"> 
                    {props.description}
            </div>
            <div className="col-span-3 p-align-l"> 
                    {props.tools}
            </div>

        </div>
    )
}

export default ProjectCell