import React from 'react'
import RandomUserCall from '../Components/RandomUserCall'

function RandomUser()
{
    return(
        <div>
            <RandomUserCall/>
        </div>
    )
}

export default RandomUser