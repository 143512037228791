import react from 'react'
import axios from "axios"




export default class RandomUserCall extends react.Component {
    state = {
      persons: []
    }
  
    componentDidMount() {
      axios.get(`https://randomuser.me/api`)
        .then(res => {
    
          this.setState({ persons: res.data });
          console.log(res.data)
        })
    }
  
    render() {
      return (
        <ul>
          { this.state.persons.map(person => <li>{person.name}</li>)}
        </ul>
      )
    }
  }




