import React from 'react'
import ProjectPanel from '../Components/ProjectPanel'
import Books from '../Images/bbb.png'
import Ticket from '../Images/tickets.png'
import Scrap from '../Images/scrapper.PNG'


function Projects()
{
    return(
        <div className="center div-content">
            <h1 className="primary-dark font-bold text-2xl">Featured Projects</h1>
            <ProjectPanel 
                description="'Books by Babel' is a 2D Tactical RPG where the player controls a handful of units
                on a large field of battle. The game has complex abilites, items, cutscenes, missions
                and maps. The design is architecture of the project is heavily modular and data driven, allowing for
                complicated and novel behavior to be easily implemented."
                image= {Books}
                projectName= "Books by Babel"
                link="https://babeldev.itch.io/books-by-babel"
                github="https://github.com/Tathomp/TacticsGame"
                tools="Unity | C# | Visual Studio"
            />

            <ProjectPanel 
                description="A tool used to track help desk tickets for employyes and administrators.
                Admins can add employees and manage tickets. Employees can claim, edit and close
                tickets. Admins can also view metrics on the site and individual users."
                image= { Ticket }
                projectName= "Issue Tracker Dashboard"
                link="https://github.com/Tathomp/TicketDashboard"
                github="https://github.com/Tathomp/TicketDashboard"
                tools="C# | ASP.NET | SQL | Chart.js"

            />

            <ProjectPanel 
                description="An automated tool that uses the Selenium library to automatically
                download the top rated video clips from popular Twitch.tv content creators. The
                videos can then be used for editing or back-up purposes."
                image= {Scrap}
                projectName= 'Twitch Clip Auto-downloader'
                link="https://github.com/Tathomp/TwitchClipScrapper"
                github="https://github.com/Tathomp/TwitchClipScrapper"
                tools="Python | Selenium | Twitch API"

            />
        </div>
    )
}

export default Projects