import React from 'react'
import Project from "./Projects"
import ProjectGrid from "./ProjectGrid"
import '../Style/stylesheet.css'
import Link from "../Images/link-solid.svg"

function Home()
{
    return(
        <div className="center py-3">
            <div className="primary-dark div-content center shadow-lg py-3">
                <div className="primary-dark grid grid-cols-3 gap-4 px-3 py-3">
                <h1 className="font-bold text-2xl">About Me</h1>
                <div className="col-span-1"></div>
                    <a href="https://github.com/Tathomp/portfolio-site" target="_blank" rel="noreferrer">
                        <img className="project-grid-icon right-image" src={ Link } alt="Github Link"/>
                    </a>
                </div>

                <p className="p-align-l ">
                    Hello, I'm Tyler, a software engineer from the Southeastern United States. 
                    I've spent most of my life with computers, exploring new technologies and working on different projects.
                    I've worked on complicated games, industry websites, automation processes
                    and data scrapping tools.</p>
                    
            </div>
            <div className="py-6">
                <Project />
            </div>
            <div className="py-6">
                <ProjectGrid />
            </div>
        </div>
    )
}

export default Home