import React from 'react'
import Navigation from './Navigation'
import '../Style/stylesheet.css'

function Header(){
    return(
        <header className='shadow-lg p-3 primary-dark flex sticky top-0 justify-between items-center'>
            <span className='font-bold'>
                <h1>
                    Tyler Thompson                    
                </h1>
            </span> 

            <Navigation />
        </header>
    )
}

export default Header;