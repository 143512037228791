import React from 'react'
import ProjectCell from '../Components/ProjectCell'
import "../Style/stylesheet.css"


function ProjectGrid()
{
    return(
    
        <div className="div-content center">
            <h1 className="textcolor-primary font-bold text-2xl py-6">
                Other Projects
            </h1>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="primary-base shadow-xl">
                    <ProjectCell
                        title="Duel Links Automation"
                        description="A series of tools that help automate some of the more tedious actions
                        a player is forced to take while playing the Android game 'Duel Links'."
                        link="https://github.com/Tathomp/DuelLinksAutoFarm"
                        tools="Python | Auto-clicker" />
                </div>
                <div className="primary-base shadow-xl">
                    <ProjectCell 
                        title="Sudoku Solver"
                        description="Sudoku game that also acts a tool to solve traditional sudoku puzzles 
                        through the use of a brute force recursive algorithm."
                        link="https://github.com/Tathomp/SudokuSolver"
                        tools="Python | Pygame"
                    />
                </div>
                <div className="primary-base shadow-xl">
                    <ProjectCell 
                        title="Stats Dashboard"
                        description="A web interface built in Dash to display crime data. "
                        link="https://github.com/Tathomp/DashMetricsSite"
                        tools="Python | Dash| Flask | pandas | plotly"
                    />
                </div>
                <div className="primary-base shadow-xl">
                    <ProjectCell 
                        title="Tank Game"
                        description="A 3rd person tank arcade game where players must navigate 3D levels, dodge bullets
                        from enemey tanks and shoot enemy tanks to increase their score. User accounts are managed through
                        a database solution."
                        link="https://github.com/Tathomp/TankProject"
                        tools="C# | PHP | Unity"
                    />
                </div>

                <div className="primary-base shadow-xl">
                    <ProjectCell 
                        title="Logic Game"
                        description="A simple Unity engine game built to test some logic gate principles for a class.
                        The purpose of the test is to provide a number of different ways for players to apply their
                        knowledge."
                        link="https://github.com/Tathomp/LogicGame"
                        tools="C# | Unity "
                    />
                </div>
                <div className="primary-base shadow-xl">
                    <ProjectCell 
                        title="Open Gym (WIP)"
                        description="Open Gym is an Android app built to track users' workout sessions, map 
                        progress and provide pre-planned routines for guidance."
                        link="https://github.com/Tathomp/OpenGym"
                        tools="Java | Android Studio | Firebase"
                    />
                </div>
            </div>
        </div>
        
    )
}

export default ProjectGrid

