import React from 'react'

function About()
{
    return(
        <div>
           <h1 className="center primary-dark text-2xl">
           Font Awesome:  <a className="textcolor-alt" href="https://fontawesome.com/license" > license.</a> 
           </h1>
        </div>
    )
}

export default About